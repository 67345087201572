<template>
	<div>
		<div class="loginCon">
			<div class="loginAll frameCon">
				<div>
					<ClockTime></ClockTime>
				</div>
				<div class="loginForm">
					<div class="topCheckLogin">
						<span class="pc_login"></span>
						<!-- <span v-else class="phone_login"></span> -->
					</div>
					<div class="passLogin" v-loading="ruleForm.loading" :element-loading-text="$t('tips.loading')">
						<!-- <div class="passLogin_Tips"></div> -->
						<h2 class="login_pass_tit">
							<!-- 密码登录 -->
							{{ $t('register.Password_Login') }}
						</h2>
						<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="">
							<el-form-item :label="$t('i18nn_b00576fed9b3a1f2')" prop="account">
								<el-input v-model="ruleForm.account" name="hyUserCode"
									:placeholder="$t('register.Please_Enter') + $t('i18nn_b00576fed9b3a1f2')"
									@keyup.enter.native="submitForm('ruleForm')"></el-input>
							</el-form-item>
							<el-form-item :label="$t('register.Password')" prop="password">
								<el-input type="password" v-model="ruleForm.password" maxlength="500" name="hyPassword"
									:placeholder="$t('register.Please_Enter')+ $t('register.Password')"
									@keyup.enter.native="submitForm('ruleForm')" show-password></el-input>
							</el-form-item>
						</el-form>
						
						<!-- <div style="text-align: right;">
							<router-link :to="{ name: 'forgotPassword' }">
								{{ $t('register.Forget_the_password') }}
							</router-link>
						</div> -->

						<div class="login_btn" style="padding-top: 20px;">
							<!-- <el-checkbox v-model="ruleForm.isSave" @keyup.enter.native="submitForm('ruleForm')">
								{{ $t('register.Next_automatic_login') }}
							</el-checkbox> -->
							
							<!-- <el-form-item> -->
							<el-button type="warning" style="width: 100%;" @click="submitForm('ruleForm')">
								<!-- 登 录 -->
								{{ $t('register.Sign_in') }}
							</el-button>

							<!-- <div class="netLine">
								<a href="https://warehouse.hytx.com/" title="https://warehouse.hytx.com/" target="_blank">
									<span
										v-if="'warehouse.hytx.com'==CurrentHostName">(<span>{{$t('i18nn_89304b37afa2f1b0')}}</span>)</span>
									<span><span>{{$t('i18nn_578842e17fea3b6a')}}</span>:<span>{{$t('i18nn_cb143acd6c929826')}}</span>(CN)</span>
								</a>
								<a href="https://uswarehouse.hytx.com/" title="https://uswarehouse.hytx.com/" target="_blank">
									<span
										v-if="'uswarehouse.hytx.com'==CurrentHostName">(<span>{{$t('i18nn_89304b37afa2f1b0')}}</span>)</span>
									<span><span>{{$t('i18nn_4fd9ca7a902c7edb')}}</span>:<span>{{$t('i18nn_09b2a7a32b3c8db1')}}</span>(US)</span>
								</a>
							</div> -->

							<!-- </el-form-item> -->
							<div class="loginLink">
								<router-link :to="{ name: 'forgotPassword' }">
									{{ $t('register.Forget_the_password') }}
								</router-link>
								<router-link :to="{ name: 'register' }">
									<!-- 新用户注册 -->
									{{ $t('register.New_user_registration') }}
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="frameCon" style="">
			<WorldTime></WorldTime>
		</div>
	</div>
</template>

<script>
	// import crypto from "crypto" //在使用的页面引入加密插件
	// import QrcodeVue from 'qrcode.vue';
	// import lwa from '@/components/Login/components/lwa.vue';
	import WorldTime from '@/components/Common/WorldTime.vue';

	import ClockTime from '@/components/Common/ClockTime.vue';

	export default {
		name: 'login',
		components: {
			WorldTime,
			ClockTime
		},
		//meta信息seo用
		// metaInfo() {
		//   return {
		//     title: this.$t("meta.main"),
		//   }
		// },

		data() {
			return {
				CurrentHostName: window.location.hostname,

				loadPackNum: '',
				ruleForm: {
					loading: false,
					// isSave: false,
					account: '',
					// userType: '',
					password: ''
				},

				rules: {
					account: [{
							required: true,
							message: this.$t('register.Please_Enter'),
							trigger: 'blur'
						},
						// {
						// 	pattern: this.$Validate.REGEX_PHONE_International,
						// 	message: this.$t('register.placeholder_phone')
						// }
						// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
					],

					password: [{
							required: true,
							message: this.$t('register.Please_Enter') + ' ' + this.$t('register.Password'),
							trigger: 'blur'
						}
						// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
					]
					// userType: [
					//   { required: true, message: this.$t('i18nn_eae360b6837f15e9'), trigger: 'change' }
					// ]
				},
				passLogin: true, //默认密码登录
				loading_code: false,
				myQrCode: '',
				//定时器
				timer: {},
				timer_time: 10 * 1000,
				timer2: {},
				timer2_time: 1 * 60 * 1000
			};
		},
		created() {
			// console.log("created");
			this.$parent.loginTitle = this.$t('register.loginTitle');
			//清除登录信息
			this.$store.dispatch('USER_SIGNOUT');

			this.init();
		},
		//离开本页面
		beforeDestroy() {
			// if (this.timer) { //清空定时器
			//   clearInterval(this.timer); //关闭
			// }
			// if (this.timer2) { //清空定时器
			//   clearInterval(this.timer2); //关闭
			// }
		},
		mounted() {
			console.log('mounted');
			// this.$Utils.closeChatBox();
		},
		methods: {
			//初始化
			init() {
				// if (this.timer2) { //清空定时器
				//   clearTimeout(this.timer2); //
				// }
				// if (this.timer) { //清空定时器
				//   clearTimeout(this.timer); //
				// }
				//轮询查询，二维码code
				// this.getQRCode();
				//用qrcode轮询登录
				// this.loginQRCode();
				//运行页面运行时间
				// this.autoRemainingTime();
			},
			// 页面停留时间累积
			// autoLoginCode(){
			//   // let _this = this;
			//   this.timer = setTimeout(_ => {
			//     // return  this.remainingTimeCount(timeStr);
			//     // this.remaTimeCount++;
			//     // this.autoRemainingTime();

			//   }, 1000);
			//   // return this.remainingTimeCount(timeStr);
			// },
			//切换登录方式
			checkLoginStyle() {
				this.passLogin = !this.passLogin;
			},
			//获得可扫码的二维码
			// getQRCode() {
			//   this.loading_code = true;
			//   this.$http.post(this.$urlConfig.HyGetQRCode, {})
			//     .then(({
			//       data
			//     }) => {
			//       console.log(this.$t('i18nn_9aa7aa827e01b057'));
			//       console.log(data);
			//       this.loading_code = false;
			//       if ('200' === data.code && !!data.data) {
			//         this.myQrCode = data.data;
			//         clearTimeout(this.timer2);
			//         this.timer2 = setTimeout(_ => {
			//           if (!this.passLogin) {
			//             this.getQRCode();
			//           }
			//         }, this.timer2_time);
			//       } else {
			//         // this.$message.warning(data.msg?data.msg:this.$t('i18nn_bb456d91d96d66fa'));
			//         clearTimeout(this.timer2);
			//         this.timer2 = setTimeout(_ => {
			//           if (!this.passLogin) {
			//             this.getQRCode();
			//           }
			//         }, this.timer_time);
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       this.loading_code = false;
			//       // this.$message.warning(this.$t('i18nn_bb456d91d96d66fa'));
			//       clearTimeout(this.timer2);
			//       this.timer2 = setTimeout(_ => {
			//         if (!this.passLogin) {
			//           this.getQRCode();
			//         }
			//       }, this.timer_time);
			//     });
			// },
			//轮询调用二维码登录
			// loginQRCode() {
			//   // this.loading_code = true;
			//   if (!this.passLogin && !!this.myQrCode && '' != this.myQrCode) {
			//     this.$http.post(this.$urlConfig.HyLoginQRCode, {
			//         qrCodeStr: this.myQrCode
			//       })
			//       .then(({
			//         data
			//       }) => {
			//         console.log("调用二维码登录，请求成功");
			//         console.log(data);
			//         // this.loading_code = false;
			//         if ('200' === data.code && !!data.data) {
			//           this.loginSuccess(data.data, data.role);
			//         } else {
			//           clearTimeout(this.timer);
			//           this.timer = setTimeout(_ => {
			//             this.loginQRCode();
			//           }, this.timer_time);
			//           // this.$message.warning(data.msg?data.msg:this.$t('i18nn_5fe6cc0473e0d313'));
			//         }
			//       })
			//       .catch((error) => {
			//         console.log(error);
			//         clearTimeout(this.timer);
			//         this.timer = setTimeout(_ => {
			//           this.loginQRCode();
			//         }, this.timer_time);
			//         // this.loading_code = false;
			//         // this.$message.warning(this.$t('i18nn_5fe6cc0473e0d313'));
			//       });
			//   } else {
			//     console.log("clearTimeout this.myQrCode", this.myQrCode);
			//     clearTimeout(this.timer);
			//     this.timer = setTimeout(_ => {
			//       this.loginQRCode();
			//     }, this.timer_time);
			//   }

			// },
			//去注册
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						console.log(this.$store.state);
						this.loginAction();
						// this.mobileLoginAction();
					} else {
						console.log('error submit!!');
						this.$message.warning(this.$t('register.ver_login_msg'));
						return false;
					}
				});
			},
			//提交登录
			loginAction() {
				this.ruleForm.loading = true;
				this.$http
					.post(this.$urlConfig.HyLogin, {
						account: this.ruleForm.account,
						password: this.ruleForm.password
						// userType: "TALLYPERSON",
					})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_e5368dfd7865a5f1'));
						console.log(data);
						this.ruleForm.loading = false;
						if ('200' === data.code) {
							// this.loginSuccess(data.data, data.role);
							this.getPremissMenu(data.data);
						} else {
							if (!data.msg) {
								data.msg = this.$t('register.login_was_fail');
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						// alert(error);
						console.log(error);
						this.ruleForm.loading = false;
						this.$message.warning(this.$t('tips.requestErrorData'));
					});
			},
			//短信验证码，提交登录
			// mobileLoginAction() {
			// 	this.ruleForm.loading = true;
			// 	this.$http
			// 		.post(this.$urlConfig.HyMobileLogin, {
			// 			verificationCode: this.ruleForm.verificationCode,
			// 			phoneNumber: this.ruleForm.account
			// 		})
			// 		.then(({ data }) => {
			// 			console.log(this.$t('i18nn_e5368dfd7865a5f1'));
			// 			console.log(data);
			// 			this.ruleForm.loading = false;
			// 			if ('200' === data.code) {
			// 				// this.loginSuccess(data.data, data.role);
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('register.login_was_fail');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			// alert(error);
			// 			console.log(error);
			// 			this.ruleForm.loading = false;
			// 			this.$message.warning(this.$t('tips.requestErrorData'));
			// 		});
			// },
			loginSuccessCallBack(UserInfo) {


				console.log('getUserInfo', UserInfo);
				//清空展示通知状态
				window.sessionStorage.setItem("homeNotif", '0');

				console.log('sessionStorage.getItem(menusInfo)', sessionStorage.getItem('menusInfo'));
				console.log('sessionStorage.getItem(menusInfoKey)', sessionStorage.getItem('menusInfoKey'));
				console.log('Frame menus', this.$store.getters.getMenusInfo);
				console.log('menusInfoKey', this.$store.getters.getMenusInfoKey);

				//没有认证
				if ('1' != UserInfo.isWh) {
					this.$router.push({
						name: 'auth'
					});
					return;
				}
				this.$message({
					message: this.$t('register.login_was_successful'),
					type: 'success'
				});
				//重新初始化客服组件，登录
				// this.$Utils.initLoginQimoChat();
				//判断合伙人是否开户，厂家是否开户
				// this.IsPartnerOpenAccount(UserInfo);
				//判断合伙人公约是否已经阅读
				// this.IsPartnerRead(UserInfo);

				//判断仓库权限
				// this.getWhAuthDet();
				//存在历史跳转，跳转到历史
				// if (!window.history || window.history.length <= 5) {
				this.$router.push({
					name: this.$store.state.homePage
				});
				// return false;
				// } else {
				//   this.$router.go(-1)
				// }
			},

			//获得用户权限菜单
			getPremissMenu(userInfo) {
				// console.log(this);
				// let that = this;
				this.ruleForm.loading = true;
				this.$http
					.get(this.$urlConfig.WhMenusQuery)
					.then(({
						data
					}) => {
						// console.log(data);
						this.ruleForm.loading = false;
						if ('200' === data.code) {
							console.log(this.$t('i18nn_48fea1870aa235fd'));
							// this.$router.push({name:'Frame'});
							this.loginSuccess(userInfo, data.rows);
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_725d750832726cc3'));
						}
					})
					.catch(error => {
						console.log(error);
						this.ruleForm.loading = false;
						this.$message.error(this.$t('i18nn_747b33bfa850b075'));
					});
			},

			//登录成功
			loginSuccess(userinfo, whMenusArr) {
				// this.$store.dispatch('USER_SIGNOUT');
				// // console.log(this.$store.state.login.userInfo);
				this.$store.dispatch('UPDATE_USERINFO', userinfo);
				this.$store.dispatch('UPDATE_MENUS', whMenusArr);
				// this.$store.commit('UPDATE_USERINFO',userinfo)
				// console.log(window.history);
				// console.log(this.$router);
				// return;

				let UserInfo = this.$store.getters.getUserInfo;
				if (UserInfo && UserInfo.id) {
					console.log('getUserInfo success', UserInfo);
					this.loginSuccessCallBack(UserInfo);
				} else {
					// this.$alert("获取登录信息失败，请重新登录", this.$t('tips.tipsTitle'), {
					//     type:'warning',
					//     // //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					// });
					//如果第一次存不到缓存，执行第二次
					console.log('getUserInfo error111', UserInfo);
					// let _this = this;
					// this.$store.dispatch('USER_SIGNOUT');
					this.$store.dispatch('UPDATE_USERINFO', userinfo);
					this.$store.dispatch('UPDATE_MENUS', whMenusArr);
					let UserInfo2 = this.$store.getters.getUserInfo;
					// this.$store.commit('UPDATE_USERINFO',userinfo)
					if (UserInfo2 && UserInfo2.id) {
						this.loginSuccessCallBack(UserInfo2);
					} else {
						//第二次存不到 刷新浏览器
						//再次取不到刷新浏览器
						console.log('getUserInfo error222', this.$store.getters.getUserInfo);
						window.location.reload();
					}
				}
			}
			//判断合伙人，厂家是否开户
			// IsPartnerOpenAccount(UserInfo) {
			//   //合伙人 和 厂家认证 都 未认证审核通过
			//   if ('2' != UserInfo.isParnter && '2' != UserInfo.enterpriseState) { //合伙人，厂家审核通过
			//     this.$notify({
			//       title: this.$t('i18nn_44d6f2dd8045c564'),
			//       message: "完成合伙人或厂家认证，可以享受更多收益，点此去认证",
			//       duration: 0,
			//       type: 'warning',
			//       customClass:'notifyAllLink',
			//       onClick: () => {
			//         this.$router.push({
			//           name: 'UserAuthentication'
			//         });
			//       }
			//     });
			//   }
			//   //合伙人 或 厂家审核通过
			//   // if ('2' == UserInfo.isParnter||'2' == UserInfo.enterpriseState) {
			//   //   this.getOpenAccoutData(UserInfo);
			//   // }
			// },

			//查询仓库申请详情
			// getWhAuthDet() {
			//   console.log(this.$t('i18nn_d816fbcc52e724d5'));
			//   // this.loading_det = true;
			//   this.$http.get(this.$urlConfig.WhMyCustomerInfo, {
			//       // "filter": {
			//       //   "orderBy": "id_",
			//       //   "sortAsc": "desc",
			//       // },
			//       // userId: this.$store.getters.getUserInfo.id
			//     })
			//     .then(({ data }) => {
			//       console.log(this.$t('i18nn_514f4e80f3e21b6c'));
			//       console.log(data);
			//       
			//       // this.loading_det = false;

			//       if (200 == data.code && data.data) {
			//         // this.showDetailData = data.data;
			//         // if(!!data.rows && !!data.rows[0]){
			//         //   this.initForm(data.rows[0]);
			//         // }
			//         if('1'!=data.data.isWh){
			//           // this.$alert('您还没有开通仓库权限，请先申请开通', this.$t('tips.tipsTitle'), {
			//           //   type: 'warning',
			//           //   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//           // });
			//           // this.$router.push({name:"StorageAuthentication"})
			//         } else {
			//           //设置有仓库权限
			//           this.$store.dispatch('UPDATE_WH_AUTH', '1');
			//           // this.$alert(this.$t('i18nn_a9e4df17d34ba1ea'), this.$t('tips.tipsTitle'), {
			//           //   type: 'success',
			//           //   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//           // });
			//         }
			//         // if(!!data.data){
			//         //   this.initForm(data.data);
			//         // }
			//       } else {
			//         // this.$message.warning(!data.msg ? '查询认证详情请求失败，请重试' : data.msg);
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       // this.loading_det = false;
			//       // this.$message.warning('查询认证详情请求失败，请重试！');

			//     });
			// },

			//查询开户状态数据
			// getOpenAccoutData(UserInfo) {
			//   // this.loading = true;
			//   this.$http.put(this.$urlConfig.BankOpenAccountStatus, {})
			//     .then(({
			//       data
			//     }) => {
			//       console.log(this.$t('i18nn_9219b1f73e2ff140'));
			//       // console.log(data);
			//       if (200 == data.code && data.data) {
			//         let accountStatusData = data.data;
			//         //合伙人开户提示
			//         if ('2' == UserInfo.isParnter && '00' !== accountStatusData.openPeStatus) {
			//           let msg = "您的个人开户尚未完成，点此去个人开户";
			//           this.$notify({
			//             title: this.$t('i18nn_492ff5e77f754b18'),
			//             message: msg,
			//             duration: 0,
			//             type: 'warning',
			//             customClass:'notifyAllLink',
			//             onClick: () => {
			//               this.$router.push({
			//                 name: 'UserOpenAccount'
			//               });
			//             }
			//           });
			//         }
			//         //厂家开户提示
			//         if ('2' == UserInfo.enterpriseState && '00' !== accountStatusData.openEnStatus) {
			//           let msg = "您的厂家开户尚未完成，点此去厂家开户";
			//           this.$notify({
			//             title: this.$t('i18nn_0d6e3e4d92b1b3ca'),
			//             message: msg,
			//             duration: 0,
			//             type: 'warning',
			//             customClass:'notifyAllLink',
			//             onClick: () => {
			//               this.$router.push({
			//                 name: 'UserOpenAccount'
			//               });
			//             }
			//           });
			//         }

			//       } else {

			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log(this.$t('i18nn_7d842f63515c8e9b'));
			//       // this.loading = false;

			//     });
			// },
			//判断合伙人公约是否已经阅读
			// IsPartnerRead(UserInfo) {
			//   //合伙人 和 厂家认证 都 未认证审核通过
			//   // if ('2' != UserInfo.isParnter && '2' != UserInfo.enterpriseState) { //合伙人，厂家审核通过
			//   //   this.$notify({
			//   //     title: this.$t('i18nn_44d6f2dd8045c564'),
			//   //     message: "完成合伙人或厂家认证，可以享受更多收益，点此去认证",
			//   //     duration: 0,
			//   //     type: 'warning',
			//   //     customClass:'notifyAllLink',
			//   //     onClick: () => {
			//   //       this.$router.push({
			//   //         name: 'UserAuthentication'
			//   //       });
			//   //     }
			//   //   });
			//   // }
			//   //合伙人 或 厂家审核通过
			//   if ('2' == UserInfo.isParnter) {
			//     this.getPartnerRead(UserInfo);
			//   }
			// },
			//查询合伙人公约是否已经阅读
			// getPartnerRead(UserInfo) {
			//   // this.loading = true;
			//   this.$http.get(this.$urlConfig.getParentReadDet, {})
			//     .then(({
			//       data
			//     }) => {
			//       console.log(this.$t('i18nn_0fa3a046baf31028'));
			//       // console.log(data);

			//       if (200 == data.code && !!data.data) {
			//         // alert(1);
			//         let ParentReadData = data.data;
			//         // alert('2' == UserInfo.isParnter && '2' != ParentReadData.isReadTreaty);
			//         let tipsTit = '';
			//         //合伙人开户提示
			//         if ('2' == UserInfo.isParnter && '2' != ParentReadData.isReadTreaty) {
			//           let msg = "尊敬的合伙人：<br />您好！《平台合伙人公约》已更新，请前往阅读并确认，确认后我们将为您发放合伙人证书。";
			//           this.$alert(msg, tipsTit, {
			//             dangerouslyUseHTMLString: true,
			//             // type: 'warning',
			//             confirmButtonText: this.$t('i18nn_63a2c5ecd34724fb'),
			//             callback: action => {
			//               try {
			//                 let routeUrl = this.$router.resolve({name:"PartnershipAgreement"});
			//                 window.open(routeUrl.href, '_blank');
			//               } catch(err){
			//                 console.log(err);
			//                 this.$router.push({ name: 'PartnershipAgreement' });
			//               }
			//             }
			//           });
			//           // alert(11);
			//         }
			//       } else {

			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log(this.$t('i18nn_0fb7ba49e82b597b'));
			//       // this.loading = false;

			//     });
			// },
		}
	};
</script>

<style lang="less" scoped>
	// .netLine {
	// 	display: flex;
	// 	justify-content: space-around;
	// 	margin-top: 10px;
	// 	font-size: 12px;
	// }
</style>